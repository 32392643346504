<template>
  <div id="round-winlose-bottom" class="p-2">

    <div class="my-4">
      <b-input-group size="md" prepend="ดูหมายเลข" class="mb-2">
        <b-form-input v-model="findNumbers"></b-form-input>

        <div class="ml-2 px-2 d-flex align-items-center">แสดงข้อมูล</div>
        <b-input-group-prepend is-text>
          <label class="m-0 p-0">
            <input type="radio" name="profit" v-model="profit" value="win"> กำไร
          </label>
        </b-input-group-prepend>
        <b-input-group-prepend is-text>
          <label class="m-0 p-0">
            <input type="radio" name="profit" v-model="profit" value="lose"> ขาดทุน
          </label>
        </b-input-group-prepend>
        <b-input-group-prepend is-text>
          <label class="m-0 p-0">
            <input type="radio" name="profit" v-model="profit" :value="null"> ทั้งหมด
          </label>
        </b-input-group-prepend>
        <b-form-input :number="true" v-model="profitAmount" :disabled="profit!=='win'&&profit!=='lose'"></b-form-input>
      </b-input-group>

      <b-input-group prepend="เรียงลำดับ" size="md" class="mb-2">
        <b-input-group-prepend>
          <b-button :variant="sortBy==='number'?'info':'outline-info'" @click="sortBy='number';sortOrder='asc'">หมายเลข</b-button>
          <b-button :variant="sortBy==='bill'?'info':'outline-info'" @click="sortBy='bill';sortOrder='desc'">จำนวนโพย</b-button>
          <b-button :variant="sortBy==='profit-lose'?'info':'outline-info'" @click="sortBy='profit-lose'">กำไร/ขาดทุน</b-button>
        </b-input-group-prepend>
        <b-input-group-prepend is-text>
          เรียงจาก
        </b-input-group-prepend>
        <b-input-group-append>
          <b-button :variant="sortOrder==='asc'?'info':'outline-info'" @click="sortOrder='asc'">น้อย > มาก</b-button>
          <b-button :variant="sortOrder==='desc'?'info':'outline-info'" @click="sortOrder='desc'">มาก > น้อย</b-button>
        </b-input-group-append>
      </b-input-group>

      <b-input-group prepend="จำนวนแถว" size="md" class="mb-2">
        <b-input-group-append>
          <b-button :variant="rowLength===25?'info':'outline-info'" @click="rowLength=25">25</b-button>
          <b-button :variant="rowLength===50?'info':'outline-info'" @click="rowLength=50">50</b-button>
          <b-button :variant="rowLength===75?'info':'outline-info'" @click="rowLength=75">75</b-button>
          <b-button :variant="rowLength===1000?'info':'outline-info'" @click="rowLength=1000">ทั้งหมด</b-button>
        </b-input-group-append>
      </b-input-group>
    </div>

    <table class="table table-info table-sm table-bordered table-hover mb-0">
      <thead>
        <tr>
          <th>ยอดรวม</th>
          <th colspan="2">2 ตัวล่าง</th>
          <th colspan="2">วิ่งล่าง</th>
          <th colspan="3">รวม</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>ยอดรับ</td>
          <td
            colspan="2"
            class="text-right"
            :class="[{'text-success':total.twoNumberBottom.amount>0, 'text-danger':total.twoNumberBottom.amount<0}]"
          >{{ total.twoNumberBottom.amount | amountFormat(2, '0.00') }}</td>
          <td
            colspan="2"
            class="text-right"
            :class="[{'text-success':total.runBottom.amount>0, 'text-danger':total.runBottom.amount<0}]"
          >{{ total.runBottom.amount | amountFormat(2, '0.00') }}</td>
          <td
            colspan="3"
            class="text-right"
            :class="[{'text-success':total.overallBottom.amount>0, 'text-danger':total.overallBottom.amount<0}]"
          >{{ total.overallBottom.amount | amountFormat(2, '0.00') }}</td>
        </tr>
        <tr>
          <td>คอมฯ</td>
          <td
            colspan="2"
            :class="[{'text-success':total.twoNumberBottom.comission>0, 'text-danger':total.twoNumberBottom.comission<0}]"
            class="text-right"
          >{{ total.twoNumberBottom.comission | amountFormat(2, '0.00') }}</td>
          <td
            colspan="2"
            :class="[{'text-success':total.runBottom.comission>0, 'text-danger':total.runBottom.comission<0}]"
            class="text-right"
          >{{ total.runBottom.comission | amountFormat(2, '0.00') }}</td>
          <td
            colspan="3"
            :class="[{'text-success':total.overallBottom.comission>0, 'text-danger':total.overallBottom.comission<0}]"
            class="text-right"
          >{{ total.overallBottom.comission | amountFormat(2, '0.00') }}</td>
        </tr>
        <tr>
          <th>รวม</th>
          <th
            colspan="2"
            :class="[{'text-success':total.twoNumberBottom.total>0, 'text-danger':total.twoNumberBottom.total<0}]"
            class="text-right"
          >{{ total.twoNumberBottom.total | amountFormat(2, '0.00') }}</th>
          <th
            colspan="2"
            :class="[{'text-success':total.runBottom.total>0, 'text-danger':total.runBottom.total<0}]"
            class="text-right"
          >{{ total.runBottom.total | amountFormat(2, '0.00') }}</th>
          <th
            colspan="3"
            :class="[{'text-success':total.overallBottom.total>0, 'text-danger':total.overallBottom.total<0}]"
            class="text-right"
          >{{ total.overallBottom.total | amountFormat(2, '0.00') }}</th>
        </tr>
        <tr style="font-size: 110%;">
          <th colspan="5" class="text-success border-top text-center">ได้สูงสุด</th>
          <th colspan="3" class="text-success border-top text-right">{{ max.summary | amountFormat(2, '0.00') }}</th>
        </tr>
        <tr style="font-size: 110%;">
          <th colspan="5" class="text-danger text-center">เสียสูงสุด</th>
          <th colspan="3" class="text-danger text-right">{{ min.summary<0?min.summary:0 | amountFormat(2, '0.00') }}</th>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th class="border-right border-top">หมายเลข</th>
          <th colspan="2" class="border-right border-top">2 ตัวล่าง</th>
          <th colspan="2" class="border-right border-top">วิ่งล่าง</th>
          <th colspan="3"class="border-top">สรุป กำไร/ขาดทุน</th>
        </tr>
        <tr>
          <th width="180" class="border-right">2 ตัวล่าง</th>
          <td width="80">โพย</td>
          <td width="120" class="border-right">กำไร/ขาดทุน</td>
          <td width="80">โพย</td>
          <td width="120" class="border-right">กำไร/ขาดทุน</td>
          <td>จำนวนโพย</td>
          <td>%</td>
          <td>บาท</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in itemsFilter" :key="item.twoNumber">
          <td class="text-center text-primary border-right">{{item.twoNumber}}</td>
          <td class="text-center">{{ item.twoNumberBottom.length }}</td>
          <td
            class="text-right border-right text-success"
            :class="[{'text-success':item.twoNumberBottom.amount>0, 'text-danger':item.twoNumberBottom.amount<0}]"
          >{{ item.twoNumberBottom.amount | amountFormat(2, '0.00') }}</td>
          <td class="text-center">{{ item.runBottom.length }}</td>
          <td
            class="text-right border-right text-success"
            :class="[{'text-success':item.runBottom.amount>0, 'text-danger':item.runBottom.amount<0}]"
          >{{ item.runBottom.amount | amountFormat(2, '0.00') }}</td>

          <td class="text-center">{{ item.totalLength }}</td>
          <td
            class="text-center"
            :class="[{'text-success':item.percent>0, 'text-danger':item.percent<0}]"
          >{{ item.percent | amountFormat(2, '0.00') }}%</td>
          <td
            class="text-right"
            :class="[{'text-success':item.summary>0, 'text-danger':item.summary<0}]"
          >{{ (item.summary) | amountFormat(2, '0.00') }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: 'RoundWinLoseBottom',
  props: ['total', 'totalWon', 'lose'],
  data() {
    return {
      rowLength: 50,
      sortBy: 'number',
      sortOrder: 'asc',
      findNumbers: '',
      profit: null,
      profitAmount: 0
    }
  },
  computed: {
    items() {

      const numbers = []
      if(!this.findNumbers) {
        for(let i=0; i<=99; i++) {
          const twoNumber = i.toString().padStart(2, '0')
          numbers.push({
            twoNumber: twoNumber
          })
        }
      }else{
        const setNumbers = this.findNumbers.split("")
        if(setNumbers.length === 1) {
          for(let i=0; i<=9; i++) {
            numbers.push({
              twoNumber: `${i}${setNumbers[0]}`
            })
          }
        }else
        if(setNumbers.length === 2) {
          numbers.push({
            twoNumber: `${setNumbers[0]}${setNumbers[1]}`
          })
        }else{
          for(let i=0; i<=9; i++) {
            const twoNumber = `${setNumbers[setNumbers.length-2]}${setNumbers[setNumbers.length-1]}`
            numbers.push({
              twoNumber: twoNumber
            })
          }
        }
      }

      const items = []
      for(const number of numbers) {
        const twoNumber = number.twoNumber

        /**
         * แพ้/ชนะ 2 ตัวล่าง
         * (ยอดรับ + คอมฯ) + ยอดจ่าย
         *
         * หมายเหตุ คอมฯ กับยอดจ่าย มีค่าเป็นลบอยู่แล้ว เลยต้องเอามาบวก
         */
        const winLoseTwoNumberBottom = this.lose?.twoNumberBottom[twoNumber]?.amount ? (this.lose?.twoNumberBottom[twoNumber]?.reward + this.totalWon.twoNumberBottom) : this.totalWon.twoNumberBottom
        const twoNumberBottom = {
          amount: winLoseTwoNumberBottom,
          length: this.lose?.twoNumberBottom[twoNumber]?.length || 0
        }

        /**
         * เลขวิ่งบน ที่ถูกรางวัล
         */
        const runWonNumbers = _.uniq(twoNumber.split(""))
        /**
         * ยอดจ่ายวิ่งบนทั้งหมด
         */
        const runWonAmount = runWonNumbers.reduce((total, number)=>{
          total.amount += (this.lose?.runBottom[number]?.reward||0)
          if(this.lose?.runBottom[number]?.reward) {
            total.length++
          }
          return total
        }, {
          amount: 0,
          length: 0
        })
        const runBottom = {
          amount: this.totalWon.runBottom + runWonAmount.amount,
          length: runWonAmount.length
        }

        const summary = (twoNumberBottom.amount+runBottom.amount)

        items.push({
          twoNumber: twoNumber,
          twoNumberBottom: twoNumberBottom,
          runBottom: runBottom,
          totalLength: (twoNumberBottom.length+runBottom.length),
          summary: summary,
          percent: (summary/this.totalWon.overallBottom)*100,
          runWonNumbers: runWonNumbers
        })
      }
      return items
    },
    itemsFilter(){
      const findNumbers = this.findNumbers.split("")
      const itemsFilter = this.items.filter((item)=>{
        if(this.profit==='win')
          return item.summary >= parseInt(this.profitAmount)
        if(this.profit==='lose')
          return item.summary <= -parseInt(this.profitAmount)
        else
          return true
      })

      const chunks = _.chunk(_.sortBy(itemsFilter, (item)=>{
        return {
          number: this.sortOrder==='asc' ? parseInt(item.twoNumber) : -parseInt(item.twoNumber),
          bill: this.sortOrder==='asc' ? item.totalLength : -item.totalLength,
          'profit-lose': this.sortOrder==='asc' ? item.summary : -item.summary,
          'percent': this.sortOrder==='asc' ? item.percent : -item.percent
        }[this.sortBy]
      }), this.rowLength)

      return chunks[0]
    },
    max() {
      return _.maxBy(this.items, (item)=>{
        return item.summary
      })
    },
    min() {
      return _.minBy(this.items, (item)=>{
        return item.summary
      })
    }
  }
}
</script>
<style lang="scss" scoped>
#round-winlose-bottom {
  .table {
    font-size: 90%;

    thead {
      tr {
        th, td {
          text-align: center;
          vertical-align: middle;
        }
      }
    }
    tbody {
      background-color: #FFF;
    }

    th, td {
      &.border-right {
        border-right: 3px solid #86cfda !important;
      }
      &.border-top {
        border-top: 3px solid #86cfda !important;
      }
    }
  }
}
</style>
