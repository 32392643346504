<template>
  <div id="round-winlose-report" class="container-fluid pb-5">
    <div class="page-header mb-1">
      <strong>คาดคะเน ได้-เสีย</strong>
    </div>

    <b-card no-body class="mb-1 position-relative">
      <b-card-header header-tag="nav">
        <h5 class="card-title">
          <i class="fas fa-exclamation-triangle text-danger mr-2"></i>
          <span class="text-dark mr-2">เฉพาะงวด</span>
          <span class="text-primary mr-2">{{ roundName }}</span>
          <span class="text-dark mr-2">วันที่</span>
          <span class="text-primary mr-2">{{ roundDate }}</span>
          <small class="text-secondary">(เปลี่ยนได้ที่แถบเมนูด้านบน)</small>
        </h5>

        <b-nav card-header tabs>
          <b-nav-item @click="activeTab='WinLoseTop'" :active="activeTab==='WinLoseTop'">เลขบน</b-nav-item>
          <b-nav-item @click="activeTab='WinLoseBottom'" :active="activeTab==='WinLoseBottom'">เลขล่าง</b-nav-item>
        </b-nav>
      </b-card-header>

      <RoundWinLoseTop
        v-if="activeTab==='WinLoseTop'"
        :total="total"
        :totalWon="totalWon"
        :lose="lose"
      />
      <RoundWinLoseBottom
        v-if="activeTab==='WinLoseBottom'"
        :total="total"
        :totalWon="totalWon"
        :lose="lose"
      />
    </b-card>
  </div>
</template>
<script>
import moment from '@/helpers/moment'
import Swal from 'sweetalert2'
import ReportService from "@/services/ReportService"
import RoundWinLoseTop from './components/RoundWinLoseTop'
import RoundWinLoseBottom from './components/RoundWinLoseBottom'
import cAlert from '@/helpers/alert'

export default {
  name: 'RoundWinLose',
  components: {
    RoundWinLoseTop,
    RoundWinLoseBottom
  },
  data() {
    return {
      data: null,
      isLoading: false,
      activeTab: 'WinLoseTop'
    }
  },
  computed: {
    roundId() {
      return this.$store.state.globalMarket?.round?.roundId
    },
    roundName() {
      if(!this.data)
        return ''

      return `[${this.data.round.note.groupTitle}] ${this.data.round.note.marketTitle}`
    },
    roundDate() {
      if(!this.data)
        return ''

      return moment(this.data.round.roundDate.date).format("DD/MM/YYYY")
    },
    dataItems() {
      return this.data?.items || []
    },
    total() {
      return this.dataItems.reduce((total, item)=>{
        total[item._id.type].amount += item.agentAmount
        total[item._id.type].comission += item.agentCommission
        total[item._id.type].total += (item.agentAmount + item.agentCommission)

        if(['threeNumberTop', 'threeNumberTode', 'twoNumberTop', 'runTop'].includes(item._id.type)) {
          total.overallTop.amount += item.agentAmount
          total.overallTop.comission += item.agentCommission
          total.overallTop.total += (item.agentAmount + item.agentCommission)
        }else
        if(['twoNumberBottom', 'runBottom'].includes(item._id.type)) {
          total.overallBottom.amount += item.agentAmount
          total.overallBottom.comission += item.agentCommission
          total.overallBottom.total += (item.agentAmount + item.agentCommission)
        }
        return total
      }, {
        threeNumberTop: {
          amount: 0,
          comission: 0,
          total: 0
        },
        threeNumberTode: {
          amount: 0,
          comission: 0,
          total: 0
        },
        twoNumberTop: {
          amount: 0,
          comission: 0,
          total: 0
        },
        runTop: {
          amount: 0,
          comission: 0,
          total: 0
        },
        twoNumberBottom: {
          amount: 0,
          comission: 0,
          total: 0
        },
        runBottom: {
          amount: 0,
          comission: 0,
          total: 0
        },
        overallTop: {
          amount: 0,
          comission: 0,
          total: 0
        },
        overallBottom: {
          amount: 0,
          comission: 0,
          total: 0
        }
      })
    },
    totalWon() {
      return this.dataItems.reduce((total, item)=>{
        total[item._id.type] += (item.agentAmount + item.agentCommission)
        if(['threeNumberTop', 'threeNumberTode', 'twoNumberTop', 'runTop'].includes(item._id.type)) {
          total.overallTop += (item.agentAmount + item.agentCommission)
          total.amountTop += item.agentAmount
          total.comissionTop += item.agentCommission
        }else
        if(['twoNumberBottom', 'runBottom'].includes(item._id.type)) {
          total.overallBottom += (item.agentAmount + item.agentCommission)
          total.amountBottom += item.agentAmount
          total.comissionBottom += item.agentCommission
        }
        return total
      }, {
        threeNumberTop: 0,
        threeNumberTode: 0,
        twoNumberTop: 0,
        runTop: 0,
        twoNumberBottom: 0,
        runBottom: 0,
        overallTop: 0,
        amountTop: 0,
        comissionTop: 0,
        overallBottom: 0,
        amountBottom: 0,
        comissionBottom: 0
      })
    },
    lose() {
      return this.dataItems.reduce((items, item)=>{
        items[item._id.type][item._id.number] = {
          length: item.count,
          /**
           * ยอดจ่ายเมื่อถูกรางวัล
           */
          amount: (item.reward + this.totalWon[item._id.type]),
          reward: item.reward
        }
        return items
      }, {
        threeNumberTop: {},
        threeNumberTode: {},
        twoNumberTop: {},
        twoNumberBottom: {},
        runTop: {},
        runBottom: {}
      })
    },
  },
  watch: {
    roundId(n, o) {
      if(n)
        this.getReport()
    }
  },
  methods: {
    getReport() {
      this.isLoading = true
      ReportService.getRoundOverall(this.roundId)
      .then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    }
  },
  mounted() {
    this.getReport()
  }
}
</script>
