<template>
  <div id="round-winlose-top" class="p-2">

    <div class="my-4">
      <b-input-group size="md" prepend="ดูหมายเลข" class="mb-2">
        <b-form-input v-model="findNumbers"></b-form-input>

        <div class="ml-2 px-2 d-flex align-items-center">แสดงข้อมูล</div>
        <b-input-group-prepend is-text>
          <label class="m-0 p-0">
            <input type="radio" name="profit" v-model="profit" value="win"> กำไร
          </label>
        </b-input-group-prepend>
        <b-input-group-prepend is-text>
          <label class="m-0 p-0">
            <input type="radio" name="profit" v-model="profit" value="lose"> ขาดทุน
          </label>
        </b-input-group-prepend>
        <b-input-group-prepend is-text>
          <label class="m-0 p-0">
            <input type="radio" name="profit" v-model="profit" :value="null"> ทั้งหมด
          </label>
        </b-input-group-prepend>
        <b-form-input :number="true" v-model="profitAmount" :disabled="profit!=='win'&&profit!=='lose'"></b-form-input>
      </b-input-group>

      <b-input-group prepend="เรียงลำดับ" size="md" class="mb-2">
        <b-input-group-prepend>
          <b-button :variant="sortBy==='number'?'info':'outline-info'" @click="sortBy='number';sortOrder='asc'">หมายเลข</b-button>
          <b-button :variant="sortBy==='bill'?'info':'outline-info'" @click="sortBy='bill';sortOrder='desc'">จำนวนโพย</b-button>
          <b-button :variant="sortBy==='profit-lose'?'info':'outline-info'" @click="sortBy='profit-lose'">กำไร/ขาดทุน</b-button>
          <!-- <b-button :variant="sortBy==='percent'?'info':'outline-info'" @click="sortBy='percent'">กำไร/ขาดทุน (%)</b-button> -->
        </b-input-group-prepend>
        <b-input-group-prepend is-text>
          เรียงจาก
        </b-input-group-prepend>
        <b-input-group-append>
          <!-- <div class="ml-2 px-2 d-flex align-items-center">เรียงจาก</div> -->
          <b-button :variant="sortOrder==='asc'?'info':'outline-info'" @click="sortOrder='asc'">น้อย > มาก</b-button>
          <b-button :variant="sortOrder==='desc'?'info':'outline-info'" @click="sortOrder='desc'">มาก > น้อย</b-button>
        </b-input-group-append>
      </b-input-group>

      <b-input-group prepend="จำนวนแถว" size="md" class="mb-2">
        <b-input-group-append>
          <b-button :variant="rowLength===50?'info':'outline-info'" @click="rowLength=50">50</b-button>
          <b-button :variant="rowLength===100?'info':'outline-info'" @click="rowLength=100">100</b-button>
          <b-button :variant="rowLength===250?'info':'outline-info'" @click="rowLength=250">250</b-button>
          <b-button :variant="rowLength===500?'info':'outline-info'" @click="rowLength=500">500</b-button>
          <b-button :variant="rowLength===750?'info':'outline-info'" @click="rowLength=750">750</b-button>
          <b-button :variant="rowLength===1000?'info':'outline-info'" @click="rowLength=1000">ทั้งหมด</b-button>
        </b-input-group-append>
      </b-input-group>
    </div>

    <table class="table table-info table-sm table-bordered table-hover mb-0">
      <thead>
        <tr>
          <th colspan="2">ยอดรวม</th>
          <th colspan="2">3 ตัวบน</th>
          <th colspan="2">3 ตัวโต๊ด</th>
          <th colspan="2">2 ตัวบน</th>
          <th colspan="2">วิ่งบน</th>
          <th colspan="3">รวม</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="2">ยอดรับ</td>
          <td
            colspan="2"
            class="text-right"
            :class="[{'text-success':total.threeNumberTop.amount>0, 'text-danger':total.threeNumberTop.amount<0}]"
          >{{ total.threeNumberTop.amount | amountFormat(2, '0.00') }}</td>
          <td
            colspan="2"
            class="text-right"
            :class="[{'text-success':total.threeNumberTode.amount>0, 'text-danger':total.threeNumberTode.amount<0}]"
          >{{ total.threeNumberTode.amount | amountFormat(2, '0.00') }}</td>
          <td
            colspan="2"
            class="text-right"
            :class="[{'text-success':total.twoNumberTop.amount>0, 'text-danger':total.twoNumberTop.amount<0}]"
          >{{ total.twoNumberTop.amount | amountFormat(2, '0.00') }}</td>
          <td
            colspan="2"
            class="text-right"
            :class="[{'text-success':total.runTop.amount>0, 'text-danger':total.runTop.amount<0}]"
          >{{ total.runTop.amount | amountFormat(2, '0.00') }}</td>
          <td
            colspan="3"
            class="text-right"
            :class="[{'text-success':total.overallTop.amount>0, 'text-danger':total.overallTop.amount<0}]"
          >{{ total.overallTop.amount | amountFormat(2, '0.00') }}</td>
        </tr>
        <tr>
          <td colspan="2">คอมฯ</td>
          <td
            colspan="2"
            :class="[{'text-success':total.threeNumberTop.comission>0, 'text-danger':total.threeNumberTop.comission<0}]"
            class="text-right"
          >{{ total.threeNumberTop.comission | amountFormat(2, '0.00') }}</td>
          <td
            colspan="2"
            :class="[{'text-success':total.threeNumberTode.comission>0, 'text-danger':total.threeNumberTode.comission<0}]"
            class="text-right"
          >{{ total.threeNumberTode.comission | amountFormat(2, '0.00') }}</td>
          <td
            colspan="2"
            :class="[{'text-success':total.twoNumberTop.comission>0, 'text-danger':total.twoNumberTop.comission<0}]"
            class="text-right"
          >{{ total.twoNumberTop.comission | amountFormat(2, '0.00') }}</td>
          <td
            colspan="2"
            :class="[{'text-success':total.runTop.comission>0, 'text-danger':total.runTop.comission<0}]"
            class="text-right"
          >{{ total.runTop.comission | amountFormat(2, '0.00') }}</td>
          <td
            colspan="3"
            :class="[{'text-success':total.overallTop.comission>0, 'text-danger':total.overallTop.comission<0}]"
            class="text-right"
          >{{ total.overallTop.comission | amountFormat(2, '0.00') }}</td>
        </tr>
        <tr>
          <th colspan="2">รวม</th>
          <th
            colspan="2"
            :class="[{'text-success':total.threeNumberTop.total>0, 'text-danger':total.threeNumberTop.total<0}]"
            class="text-right"
          >{{ total.threeNumberTop.total | amountFormat(2, '0.00') }}</th>
          <th
            colspan="2"
            :class="[{'text-success':total.threeNumberTode.total>0, 'text-danger':total.threeNumberTode.total<0}]"
            class="text-right"
          >{{ total.threeNumberTode.total | amountFormat(2, '0.00') }}</th>
          <th
            colspan="2"
            :class="[{'text-success':total.twoNumberTop.total>0, 'text-danger':total.twoNumberTop.total<0}]"
            class="text-right"
          >{{ total.twoNumberTop.total | amountFormat(2, '0.00') }}</th>
          <th
            colspan="2"
            :class="[{'text-success':total.runTop.total>0, 'text-danger':total.runTop.total<0}]"
            class="text-right"
          >{{ total.runTop.total | amountFormat(2, '0.00') }}</th>
          <th
            colspan="3"
            :class="[{'text-success':total.overallTop.total>0, 'text-danger':total.overallTop.total<0}]"
            class="text-right"
          >{{ total.overallTop.total | amountFormat(2, '0.00') }}</th>
        </tr>
        <tr style="font-size: 110%;">
          <th colspan="10" class="text-success border-top text-center">ได้สูงสุด</th>
          <th colspan="3" class="border-top text-right">
            <span v-if="max.summary>0" class="text-success">{{ max.summary | amountFormat(2, '0.00') }}</span>
            <span v-else>-</span>
          </th>
        </tr>
        <tr style="font-size: 110%;">
          <th colspan="10" class="text-danger text-center">เสียสูงสุด</th>
          <th colspan="3" class="text-right">
            <span v-if="min.summary<0" class="text-danger">{{ min.summary | amountFormat(2, '0.00') }}</span>
            <span v-else>-</span>
          </th>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th colspan="2" class="border-right border-top">หมายเลข</th>
          <th colspan="2" class="border-right border-top">3 ตัวบน</th>
          <th colspan="2" class="border-right border-top">3 ตัวโต๊ด</th>
          <th colspan="2" class="border-right border-top">2 ตัวบน</th>
          <th colspan="2" class="border-right border-top">วิ่งบน</th>
          <th colspan="3"class="border-top">สรุป กำไร/ขาดทุน</th>
        </tr>
        <tr>
          <th width="100">3 ตัวบน</th>
          <th width="80" class="border-right">2 ตัวบน</th>
          <td width="80">โพย</td>
          <td width="120" class="border-right">กำไร/ขาดทุน</td>
          <td width="80">โพย</td>
          <td width="120" class="border-right">กำไร/ขาดทุน</td>
          <td width="80">โพย</td>
          <td width="120" class="border-right">กำไร/ขาดทุน</td>
          <td width="80">โพย</td>
          <td width="120" class="border-right">กำไร/ขาดทุน</td>
          <td>จำนวนโพย</td>
          <td>%</td>
          <td>บาท</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in itemsFilter" :key="item.threeNumber+item.twoNumber">
          <td class="text-center text-primary">{{item.threeNumber}}</td>
          <td class="text-center text-primary border-right">{{item.twoNumber}}</td>

          <td class="text-center">{{ item.threeNumberTop.length }}</td>
          <td
            class="text-right border-right text-success"
            :class="[{'text-success':item.threeNumberTop.amount>0, 'text-danger':item.threeNumberTop.amount<0}]"
          >{{ item.threeNumberTop.amount | amountFormat(2, '0.00') }}</td>

          <td class="text-center">{{ item.threeNumberTode.length }}</td>
          <td
            class="text-right border-right text-success"
            :class="[{'text-success':item.threeNumberTode.amount>0, 'text-danger':item.threeNumberTode.amount<0}]"
          >{{ item.threeNumberTode.amount | amountFormat(2, '0.00') }}</td>

          <td class="text-center">{{ item.twoNumberTop.length }}</td>
          <td
            class="text-right border-right text-success"
            :class="[{'text-success':item.twoNumberTop.amount>0, 'text-danger':item.twoNumberTop.amount<0}]"
          >{{ item.twoNumberTop.amount | amountFormat(2, '0.00') }}</td>

          <td class="text-center">{{ item.runTop.length }}</td>
          <td
            class="text-right border-right text-success"
            :class="[{'text-success':item.runTop.amount>0, 'text-danger':item.runTop.amount<0}]"
          >{{ item.runTop.amount | amountFormat(2, '0.00') }}</td>

          <td class="text-center">{{ item.totalLength }}</td>
          <td
            class="text-center"
            :class="[{'text-success':item.percent>0, 'text-danger':item.percent<0}]"
          >{{ item.percent | amountFormat(2, '0.00') }}%</td>
          <td
            class="text-right"
            :class="[{'text-success':item.summary>0, 'text-danger':item.summary<0}]"
          >{{ (item.summary) | amountFormat(2, '0.00') }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import _ from 'lodash'

export default {
  name: 'RoundWinLoseTop',
  props: ['total', 'totalWon', 'lose'],
  data() {
    return {
      rowLength: 50,
      sortBy: 'number',
      sortOrder: 'asc',
      findNumbers: '',
      profit: null,
      profitAmount: 0
    }
  },
  computed: {
    items() {

      const numbers = []
      if(!this.findNumbers) {
        for(let i=0; i<=999; i++) {
          const threeNumber = i.toString().padStart(3, '0')
          const twoNumber = i<100 ? i.toString().padStart(2, '0') : ''
          numbers.push({
            threeNumber: threeNumber,
            twoNumber: twoNumber
          })
        }
      }else{
        const setNumbers = this.findNumbers.split("")
        if(setNumbers.length === 1) {
          for(let i=0; i<=9; i++) {
            const twoNumber = `${i}${setNumbers[0]}`
            for(let j=0; j<=9; j++) {
              const threeNumber = `${j}${i}${setNumbers[0]}`
              numbers.push({
                threeNumber: threeNumber,
                twoNumber: twoNumber
              })
            }
          }
        }else
        if(setNumbers.length === 2) {
          for(let i=0; i<=9; i++) {
            const twoNumber = `${setNumbers[0]}${setNumbers[1]}`
            const threeNumber = `${i}${setNumbers[0]}${setNumbers[1]}`
            numbers.push({
              threeNumber: threeNumber,
              twoNumber: twoNumber
            })
          }
        }else
        if(setNumbers.length === 3) {
          numbers.push({
            threeNumber: `${setNumbers[0]}${setNumbers[1]}${setNumbers[2]}`,
            twoNumber: `${setNumbers[1]}${setNumbers[2]}`
          })
        }else{
          for(let i=0; i<=9; i++) {
            const twoNumber = `${setNumbers[setNumbers.length-2]}${setNumbers[setNumbers.length-1]}`
            const threeNumber = `${i}${twoNumber}`
            numbers.push({
              threeNumber: threeNumber,
              twoNumber: twoNumber
            })
          }
        }
      }

      const items = []
      for(const number of numbers) {
        const threeNumber = number.threeNumber
        const twoNumber = number.twoNumber
        /**
         * แพ้/ชนะ 3 ตัวบน
         * (ยอดรับ + คอมฯ) + ยอดจ่าย
         *
         * หมายเหตุ คอมฯ กับยอดจ่าย มีค่าเป็นลบอยู่แล้ว เลยต้องเอามาบวก
         */
        const winLoseThreeNumberTop = this.lose?.threeNumberTop[threeNumber]?.amount ? (this.lose?.threeNumberTop[threeNumber]?.reward + this.totalWon.threeNumberTop) : this.totalWon.threeNumberTop
        const threeNumberTop = {
          amount: winLoseThreeNumberTop,
          length: this.lose?.threeNumberTop[threeNumber]?.length || 0
        }

        /**
         * แพ้/ชนะ 3 ตัวโต๊ด
         * (ยอดรับ + คอมฯ) + ยอดจ่าย
         *
         * หมายเหตุ คอมฯ กับยอดจ่าย มีค่าเป็นลบอยู่แล้ว เลยต้องเอามาบวก
         */

        /**
         * เลขโต๊ดที่ถูกรางวัล
         */
        const sps = threeNumber.split("")
        const todeWinNumbers = _.uniq([
          `${sps[0]}${sps[1]}${sps[2]}`,
          `${sps[0]}${sps[2]}${sps[1]}`,
          `${sps[1]}${sps[0]}${sps[2]}`,
          `${sps[1]}${sps[2]}${sps[0]}`,
          `${sps[2]}${sps[0]}${sps[1]}`,
          `${sps[2]}${sps[1]}${sps[0]}`
        ])
        /**
         * ยอดจ่าย 3 ตัวโต๊ดทั้งหมด
         */
        const todeWonAmount = todeWinNumbers.reduce((total, number)=>{
          total.amount += (this.lose?.threeNumberTode[number]?.reward||0)
          if(this.lose?.threeNumberTode[number]?.reward) {
            total.length++
          }
          return total
        }, {
          amount: 0,
          length: 0
        })
        const threeNumberTode = {
          amount: this.totalWon.threeNumberTode + todeWonAmount.amount,
          length: todeWonAmount.length
        }

        /**
         * แพ้/ชนะ 2 ตัวบน
         * (ยอดรับ + คอมฯ) + ยอดจ่าย
         *
         * หมายเหตุ คอมฯ กับยอดจ่าย มีค่าเป็นลบอยู่แล้ว เลยต้องเอามาบวก
         */
        const winLoseTwoNumberTop = this.lose?.twoNumberTop[twoNumber]?.amount ? (this.lose?.twoNumberTop[twoNumber]?.reward + this.totalWon.twoNumberTop) : this.totalWon.twoNumberTop
        const twoNumberTop = {
          amount: winLoseTwoNumberTop,
          length: this.lose?.twoNumberTop[twoNumber]?.length || 0
        }

        /**
         * เลขวิ่งบน ที่ถูกรางวัล
         */
        const runWonNumbers = _.uniq(threeNumber.split(""))
        /**
         * ยอดจ่ายวิ่งบนทั้งหมด
         */
        const runWonAmount = runWonNumbers.reduce((total, number)=>{
          total.amount += (this.lose?.runTop[number]?.reward||0)
          if(this.lose?.runTop[number]?.reward) {
            total.length++
          }
          return total
        }, {
          amount: 0,
          length: 0
        })
        const runTop = {
          amount: this.totalWon.runTop + runWonAmount.amount,
          length: runWonAmount.length
        }

        const summary = (threeNumberTop.amount+threeNumberTode.amount+twoNumberTop.amount+runTop.amount)

        items.push({
          threeNumber: threeNumber,
          twoNumber: twoNumber,
          threeNumberTop: threeNumberTop,
          threeNumberTode: threeNumberTode,
          twoNumberTop: twoNumberTop,
          runTop: runTop,
          totalLength: (threeNumberTop.length+threeNumberTode.length+twoNumberTop.length+runTop.length),
          summary: summary,
          percent: (summary/this.totalWon.overallTop)*100,
          runWonNumbers: runWonNumbers
        })
      }
      return items
    },
    itemsFilter(){
      const findNumbers = this.findNumbers.split("")
      const itemsFilter = this.items.filter((item)=>{
        if(this.profit==='win')
          return item.summary >= parseInt(this.profitAmount)
        if(this.profit==='lose')
          return item.summary <= -parseInt(this.profitAmount)
        else
          return true
      })

      const chunks = _.chunk(_.sortBy(itemsFilter, (item)=>{
        return {
          number: this.sortOrder==='asc' ? parseInt(item.threeNumber) : -parseInt(item.threeNumber),
          bill: this.sortOrder==='asc' ? item.totalLength : -item.totalLength,
          'profit-lose': this.sortOrder==='asc' ? item.summary : -item.summary,
          'percent': this.sortOrder==='asc' ? item.percent : -item.percent
        }[this.sortBy]
      }), this.rowLength)

      return chunks[0]
    },
    max() {
      return _.maxBy(this.items, (item)=>{
        return item.summary
      })
    },
    min() {
      return _.minBy(this.items, (item)=>{
        return item.summary
      })
    }
  }
}
</script>
<style lang="scss" scoped>
#round-winlose-top {
  .table {
    font-size: 90%;

    thead {
      tr {
        th, td {
          text-align: center;
          vertical-align: middle;
        }
      }
    }
    tbody {
      background-color: #FFF;
    }

    th, td {
      &.border-right {
        border-right: 3px solid #86cfda !important;
      }
      &.border-top {
        border-top: 3px solid #86cfda !important;
      }
    }
  }
}
</style>
